<template>
  <div class="fxlogin">
    <div class="byp"></div>
    <div class="fx-topcolor-wrap">
      <div class="fx-topcolor"></div>
    </div>
    <div class="fxwrap">
      <div class="fxcontent">
        <img class="fxmain-logo" :src="config.SITE_LOGO_URL" alt="logo" />
        <div class="fxm-div"></div>
        <div class="fspacer"></div>
        <div class="fxtitle">
          <div class="fxtl fxt2" style="font-size: 0.8em;">
            Complete user setup for<br />{{ email }}
          </div>
        </div>
        <form name="login">
          <div class="fspacer"></div>
          <div class="fxlabel">Full Name</div>
          <k-text-input
            ref="name"
            :required="true"
            :default-focus="true"
            :value="name"
            @change="onChangeName"
            @validation="onValidation"
          />
          <div class="fspacer"></div>
          <div class="fxlabel">New Password</div>
          <k-text-input
            ref="password"
            :required="true"
            :default-focus="true"
            type="password"
            @change="onChangePassword"
            @validation="onValidation"
          />
          <div class="fspacer"></div>
          <div class="fxlabel">Confirm Password</div>
          <k-text-input
            ref="confirmpassword"
            :required="true"
            :default-focus="true"
            type="password"
            @change="onChangeConfirmPassword"
            @validation="onValidation"
          />
          <div style="height:20px;"></div>
          <div class="fspacer"></div>
          <input type="button" value="Continue" class="fxmvc-btn" @click="onClickContinue" />
        </form>
        <div v-if="error" class="error">Error: {{ error }}</div>
      </div>
    </div>
    <div class="bgtint bgtint-grad"></div>
    <div class="bgtint bgtint1"></div>
    <img src="assets/default/bg-shapes.svg" class="lg-bg-img" />
    <div class="lgn-wrapper">
      <div class="scene">
        <!-- Top right circle with blinds-style-band -->
        <div v-parallax="0.35" class="scene-layer">
          <img src="assets/default/b-circle1.svg" class="lbg lbg-1" />
        </div>

        <div v-parallax="0.25" class="scene-layer">
          <img src="assets/default/b-circle2.svg" class="lbg lbg-2" />
        </div>

        <!-- Right side cirlce & small circle with a line running outward from center to bottom right -->
        <div v-parallax="0.08" class="scene-layer">
          <img src="assets/default/b-lines1.svg" class="lbg lbg-3 ani-4" />
        </div>

        <!-- Center slightly-down dots-triangle -->
        <div v-parallax="0.1" class="scene-layer">
          <img src="assets/default/b-dotarw.svg" class="lbg lbg-4" />
        </div>

        <!-- Center slightly-up dots-rectangle -->
        <div v-parallax="0.18" class="scene-layer">
          <img src="assets/default/b-dots1.svg" class="lbg lbg-5" />
        </div>

        <!-- Center bottom circle with blinds-style-band -->
        <div v-parallax="0.13" class="scene-layer ">
          <img src="assets/default/b-circle1.svg" class="lbg lbg-6 ani-2" />
        </div>

        <!-- Left bottom dots-rectangle -->
        <div v-parallax="0.06" class="scene-layer">
          <img src="assets/default/b-dots1.svg" class="lbg lbg-7" />
        </div>

        <!-- Left top circle with blinds-style-band -->
        <div v-parallax="0.1" class="scene-layer">
          <img src="assets/default/b-circle1.svg" class="lbg lbg-8 ani-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import axios from 'axios';
import validationsMixin from '~/adready-vue/components/mixins/validations-mixin';
import config from '~/config';

export default {
  name: 'AppConsoleCreatePassword',

  mixins: [validationsMixin.events],

  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
    userId: {
      required: true,
      type: String,
    },
    token: {
      required: true,
      type: String,
    },
    email: {
      required: true,
      type: String,
    },
    inputFullName: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      name: null,
      password: null,
      confirmPassword: null,
      errorMsg: null,
      config,
      mode: this.$route.query.mode,
    };
  },
  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  created() {
    if (!this.userId || isBlank(this.token)) {
      this.$router.replace({ name: 'Login' });
    }
    this.name = this.inputFullName;
  },

  methods: {
    onChangeName(val) {
      this.name = val;
    },

    onChangePassword(val) {
      this.password = val;
    },

    onChangeConfirmPassword(val) {
      this.confirmPassword = val;
    },

    onClickContinue() {
      if (isBlank(this.name)) {
        this.$refs.name.focus();
        return;
      }

      if (isBlank(this.password) || this.password.length < 6) {
        this.errorMsg = 'Password cannot be blank. Must be at least 6 characters.';
        return;
      }

      if (this.password !== this.confirmPassword) {
        this.errorMsg = 'Passwords do not match';
        return;
      }

      this.errorMsg = null;

      axios
        .post(
          `${config.ADREADY_URL}/api/auth/create_password`,
          {
            userId: this.userId,
            token: this.token,
            name: this.name,
            password: this.password,
          },
          { withCredentials: true }
        )
        .then(() => {
          window.location = config.APP_URL;
        })
        .catch((error) => {
          this.errorMsg = 'failed to create password';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800';
</style>
<style lang="scss" scoped>
.fxlogin {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.fxmain-logo {
  width: auto;
  height: 70px;
  margin: 0px;
  margin-bottom: 40px;
  transition: all 0.3s ease;
}
.fxcontent {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  max-width: 490px;
}

.fxloginimg {
  width: 420px;
  height: 373px;
}
.fxwrap {
  width: 600px;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  max-width: 37%;
  width: 100%;
  min-width: 400px;
  padding-bottom: 4vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.95) 40%,
    rgba(244, 245, 250, 0.97) 100%
  );
  box-shadow: 50px 0px 80px -50px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: saturate(280%) blur(10px);
  backdrop-filter: saturate(20%) blur(2px);
}
input[type='button'] {
  width: 100%;
}
.fxmvc-btn {
  background-color: var(--primarycolor);
  font-size: 13px;
  font-family: arial, 'Manrope', sans-serif;
  padding: 18px 18px;
  border: 0px;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  -moz-appearance: none;
  appearance: none;
  color: var(--buttontext);
  text-transform: uppercase;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 400;
  border-radius: 9px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-align: center;
  min-width: 170px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0px 10px 10px -10px rgba(0, 70, 90, 0.3);
}

.fxmvc-btn:hover {
  letter-spacing: 0.15em;
  filter: brightness(1.1);
  box-shadow: 0px 20px 20px -20px rgba(0, 70, 90, 0.5);
}
.fxlabel {
  font-family: 'Manrope', sans-serif;
  font-size: 1em;
  color: var(--primarydark2);
  margin: 20px 0 4px 0;
  font-weight: 500;
  letter-spacing: 0.03em;
}

::v-deep(.form-input-field) {
  box-sizing: border-box;
  width: 100%;
  padding: 17px 20px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #c5d2d8;
  -webkit-appearance: none;
  border-radius: 9px;
}
::v-deep(.form-input-field:hover) {
  border: 1px solid #8fc2d9;
}
.fxinput {
  box-sizing: border-box;
  width: 100%;
  padding: 17px 20px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #c5d2d8;
  -webkit-appearance: none;
  border-radius: 9px;
}
.fxinput:hover {
  border: 1px solid #8fc2d9;
}
input:focus-visible {
  outline: 1px solid var(--primarycolor);
  border-radius: 9px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0.7) inset !important;
}

.fxtitle {
  font-family: 'Manrope', sans-serif;
  color: var(--primarydark);
  font-size: 2.7em;
  font-weight: 600;
  margin-bottom: 50px;
  line-height: 1em;
}
.byp {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99;
}
.lg-bg-img {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  display: none;
  width: 140vw;
  opacity: 0.6;
  transform: translate(-42%, -50%);
}
.bgtint {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: var(--primarycolor);
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.03) 1px, rgba(0, 0, 0, 0) 1px);
  background-size: 5px 5px;
}

.bgtint-grad {
  background-color: #d7d8d9;
  background: var(--primarycolor);
  background: linear-gradient(45deg, rgba(214, 214, 214, 1) 0%, rgba(117, 117, 117, 1) 100%);
  background: linear-gradient(45deg, rgba(214, 214, 214, 1) 43%, rgba(117, 117, 117, 1) 100%);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0) 43%, rgba(0, 0, 0, 1) 100%);
  z-index: 3;
  opacity: 0.3;
}
.fx-forgot {
  padding: 6px 1px 1px;
  text-align: right;
}
.fx-forgot span {
  font-family: 'Manrope', sans-serif;
  font-size: 0.86em;
  font-weight: 600;
  color: var(--primarycolor);
  text-align: right;
  cursor: pointer;
}

.fx-forgot span:hover {
  color: var(--primarydark2);
}

.fx-topcolor,
.fx-topcolor-wrap,
.fxm-div,
.fxm-corner-img-wrap {
  display: none;
}
.fxtl1 {
  font-size: 1.1em;
  color: var(--primarycolor);
}
.fspacer {
  display: none;
  height: 300px;
  transition: all 0.7s ease;
}
.nospace {
  height: 1px;
}
@media (max-height: 760px) {
  .fxmain-logo {
    margin-bottom: 30px;
  }
  .fxtitle {
    margin-bottom: 20px;
    font-size: 2.4em;
  }
}
@media (max-height: 690px) {
  .fxwrap {
    display: inline-block;
    overflow: scroll;
  }
}

@media (max-width: 600px) {
  .fxm-corner-img-wrap {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 8;
    display: inline-block;
    opacity: 0.6;
  }

  .fxm-div {
    position: absolute;
    display: block;
    width: calc(100% + 70px);
    height: 1px;
    margin-left: -35px;
    border-bottom: 1px solid #eee;
  }

  .fx-topcolor-wrap {
    position: absolute;
    z-index: 9;
    display: block;
    width: 100%;
    height: 95px;
    overflow: hidden;
  }

  .fx-topcolor {
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    width: 600px;
    height: 0;
    border-top: 18px solid var(--primarycolor);
    border-right: 600px solid var(--primarycolor);
    border-bottom: 20px solid transparent;
    opacity: 0.9;
  }

  .fxwrap {
    width: 100%;
    max-width: 100%;
  }
  .bgtint,
  .lg-bg-img,
  .lgn-wrapper {
    display: none !important;
  }

  .fxtitle {
    margin-top: 40px;
    margin-bottom: 23px;
    font-size: 2em;
    font-weight: 700;
    line-height: 1.1em;
  }
  .fxmain-logo {
    margin-bottom: 5px;
  }
  .fxlogin,
  .fxwrap {
    position: relative;
    min-width: 280px;
    overflow: hidden;
  }
  .fxcontent {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    padding: 52px 35px;
  }
} /*600 @media end*/

.lgn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  z-index: 4;
  opacity: 0.7;
}
.lgn-wrapper .scene {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  background: transparent;
}
.lgn-wrapper .scene-layer {
  position: absolute;
}

.lbg {
  position: absolute;
}

.lbg-1 {
  top: -250px;
  right: -2050px;
  width: 700px;
}

.lbg-2 {
  top: 55vh;
  left: 27vw;
  width: 200px;
  opacity: 0.8;
  display: none;
}

.lbg-3 {
  top: -25vh;
  left: -15vw;
  width: 130vw;
  opacity: 0.6;
  filter: drop-shadow(42px 94px 10px rgba(0, 0, 0, 0.2));
}

.lbg-4 {
  top: 60vh;
  left: 70vw;
  width: 100px;
}

.lbg-5 {
  top: 18vh;
  left: 65vw;
  width: 250px;
  filter: drop-shadow(20px 40px 8px rgba(0, 0, 0, 0.3));
}

.lbg-6 {
  top: 75vh;
  left: 60vw;
  width: 400px;
}

.lbg-7 {
  top: 95vh;
  left: 32vw;
  width: 300px;
  opacity: 0.5;
  transform: rotate(90deg);
}

.lbg-8 {
  top: -220px;
  left: 17vw;
  width: 510px;
}

@keyframes randomMove {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(
      calc(100px - (200px * var(--random-x))),
      calc(100px - (200px * var(--random-y))),
      0
    );
  }
}

.ani-2 {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ani-2;
  animation-duration: 30s;
  transform-origin: top center;
}

@keyframes ani-2 {
  0% {
    transform: translate3d(-6px, -46px, 0px);
  }
  30% {
    transform: translate3d(5px, -3px, 6px);
  }
  60% {
    transform: translate3d(-15px, 15px, -15px);
  }
  100% {
    transform: translate3d(-6px, -46px, 0px);
  }
}

.ani-3 {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ani-3;
  animation-duration: 30s;
  transform-origin: top center;
}
@keyframes ani-3 {
  0% {
    transform: translate3d(6px, 46px, 0px);
  }
  30% {
    transform: translate3d(-5px, 3px, -6px);
  }
  60% {
    transform: translate3d(15px, -15px, 15px);
  }
  100% {
    transform: translate3d(6px, 46px, 0px);
  }
}
.ani-4 {
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ani-4;
  animation-duration: 30s;
  transform-origin: top center;
}
@keyframes ani-4 {
  0% {
    transform: translate3d(6px, 26px, 0px);
  }
  30% {
    transform: translate3d(-5px, 3px, -6px);
  }
  60% {
    transform: translate3d(15px, 10px, 0px);
  }
  100% {
    transform: translate3d(6px, 26px, 0px);
  }
}
.error {
  padding: 20px 0;
  font-weight: 500;
  color: #d92a36;
}
</style>
